<template>
  <v-container color='background' class="">
    <v-fade-transition ease-in-out>
    <v-row align="center" justify="center" style="height:95vh;" class="ma-0 pa-0 container-card">

      <v-col class="d-flex d-sm-none" cols="12" sm="6" md="2" lg="6" align="center" justify="center">
        <!-- <v-hover v-slot="{ isHovering, props }"> -->
        <v-img :src="require('/src/assets/logo.svg')"></v-img>
        <!-- </v-hover> -->
      </v-col>

      <!-- Comming soon -->
      <v-col cols="12" xs="6" sm="6" md="6" lg="6" align="start" justify="center">
        <v-container align="start" justify="center">
          <v-row style="margin-bottom: 25px;">
            <h1>We'll be live<br>Q2 2024!</h1>
          </v-row>
          <v-row style="margin-bottom: 15px;">
            <h2>In the meantime you can<br>reach out to us at</h2>
          </v-row>
          <v-row>
            <v-hover v-slot="{ isHovering, props }">
            <v-btn
            size='x-large'
            id="address"
            @click="copy_address()"
            v-bind="props"
            :class="`elevation-${isHovering ? 12 : 0}`"
            class="transition-swing hover-button"
            rounded="lg"
            :color="isHovering ? 'background' : 'text'" :text="isHovering ? 'Get ready to scale' : 'office@scalarx.io'" variant="flat" prepend-icon="mdi-mail"></v-btn>
            </v-hover>
          </v-row>
          <v-fade-transition ease-in-out>
          <v-row v-if="show_copy" >
            <p class="text"> copied to clipboard! </p>
          </v-row>
        </v-fade-transition>
        </v-container>
      </v-col>

      <!-- Logo -->
      <v-col class="d-none d-sm-flex" cols="6" md="6" lg="6" align="center" justify="center">
        <!-- <v-hover v-slot="{ isHovering, props }"> -->
        <v-img :src="require('/src/assets/logo.svg')"></v-img>
        <!-- </v-hover> -->
      </v-col>
    </v-row>
  </v-fade-transition>
  </v-container>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AuthView',

  components: {
  },

  data: () => ({
    show_copy: false
  }),

  methods: {
    copy_address () {
      // Get the text field
      // const copyText = document.getElementById('address')

      // Copy the text inside the text field
      navigator.clipboard.writeText('office@scalarx.io')
      this.show_copy = true

      setTimeout(() => (this.show_copy = false), 5000)
    }
  }
})
</script>

<style scoped>
h1 {
color: #fff;
font-weight: 800;
line-height: 34px;
}

h2 {
  color: #fff;
  line-height: 28px;
  font-weight: 400;
}

.text {
  color: #fff;
  line-height: 28px;
  font-weight: 400;
}

.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .9;
    position: absolute;
    width: 100%;
  }

.hover-button{
  color:#231F20 !important;
  background: red;
}
.hover-button:hover{
  color:white !important;
  background: red;
}

@media (max-width: 600px) {
  .container-card {
  flex-direction: row !important;
  flex-wrap: wrap !important;
  align-content: center !important;
  }

  .text_card {
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
