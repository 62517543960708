// Import middleware
// import auth from '@/middleware/auth'
// import guest from '@/middleware/guest'

// Views/Layouts
// import AuthLayout from '../views/AuthView.vue'

// Pages
// import Login from '../pages/Login.vue'
import AuthLayout from '../views/AuthView.vue'

// const authPages = {
//   path: '/',
//   component: AuthLayout,
//   name: 'Authentication',
//   children: [
//     {
//       path: '/login',
//       name: 'Login',
//       component: Login,
//       meta: { middleware: guest }
//     }
//     // {
//     //   path: '/register',
//     //   name: 'Register',
//     //   component: Register,
//     //   meta: { middleware: guest }
//     // }
//   ]
// }

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home'
  },
  {
    path: '/',
    name: 'Home',
    components: { default: AuthLayout }
  }
  // authPages
]

export default routes
